<template>
  <v-app>
    <div class="wrapper-app">
      <TopBarNotification />
      <v-alert
        v-show="open"
        :color="color"
        class="text-center mb-0 mx-n1 white--text"
      >
        <a class="white--text" color="primary white--text">
          {{ text }}
        </a>
        <v-btn @click="actionGo" color="primary" style="margin-left: 10px">
          Yes
        </v-btn>
        <v-btn @click="actionCancel" color="error" style="margin-left: 10px">
          Cancel
        </v-btn>
      </v-alert>
      <div class="content-app" id="app">
        <v-navigation-drawer
          v-model="drawer"
          app
          dark
          absolute
          class="navigation-drawer"
          width="390px"
        >
          <div class="top-menu">
            <div
              style="height: 49px; background: #192c53"
              class="d-flex align-center"
              :style="styleCustom"
            >
              <v-spacer></v-spacer>
              <label class="clinic-name white--text">
                {{ clinicName }}
              </label>
              <v-spacer></v-spacer>
              <v-btn
                @click="logoutApp"
                fab
                x-small
                color="transparent"
                elevation="0"
                class="mr-2"
              >
                <v-icon color="#fff">mdi-logout</v-icon>
              </v-btn>
            </div>

            <v-card height="60px" color="#fff" flat>
              <v-card-title class="pt-3">
                <img
                  :src="require('../assets/svg/pagoda.svg')"
                  width="26px"
                  height="35px"
                />
                <v-spacer></v-spacer>
                <h2 class="salutation">{{ salutation }}, {{ userName }}!</h2>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
          </div>

          <div
            v-if="permitionsMenu"
            class="d-flex justify-center align-center"
            key="menuKey"
          >
            <div class="main-widgets">
              <div
                class="container"
                v-for="(item, index) in routes"
                :key="index"
                style="max-width: 50%"
              >
                <a @click="goToRoute(item.path)">
                  <img
                    width="100%"
                    height="100%"
                    :src="require(`../assets/svg/${item.meta.svg}`)"
                  />
                </a>
                <div v-if="item.badge && item.badge > 0" class="badge">
                  {{ item.badge }}
                </div>
              </div>
            </div>
          </div>
        </v-navigation-drawer>

        <v-app-bar height="49px" app flat absolute color="#192c53" dark>
          <v-btn icon rounded @click="toGoBack()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          {{ $store.state.routeName ?? $route.name }}
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="drawer = !drawer" />
        </v-app-bar>
        <v-main>
          <snack-bar />
          <router-view />
          <MessagesMenu />
          <v-dialog width="unset" v-model="dialog">
            <UpdateLog :changes="changes" @close="dialog = !dialog" />
          </v-dialog>
        </v-main>
      </div>
    </div>
  </v-app>
</template>

<script>
import MessagesMenu from '../components/messages/MessagesMenu.vue';
import SnackBar from '../components/SnackBar.vue';
import TopBarNotification from '../components/TopBarNotification';
import UpdateLog from '../components/UpdateLog.vue';
import { authorizedRoutes } from '../router';
import { api, auth, messages, partner } from '../sharedPlugin';
import { logoutHelper } from '../utils/logout';
import axios from 'axios';
import Storage from '../utils/storage';
import device from '../utils/device';
import moment from 'moment';

export default {
  components: { MessagesMenu, SnackBar, UpdateLog, TopBarNotification },
  name: 'Home',
  data: () => ({
    loadingRoutes: false,
    dialog: false,
    changes: '',
    drawer: true,
    urls: null,
    lastRoute: null,
    menuKey: Math.random(),
    open: false,
    checkUrl: null,
    path: null,
    color: 'red',
    permitionsMenu: null,
    text: 'You are about to navigate away from your Intake form. If you do this you may lose the information you entered. Are you sure you want to leave ?',
    unreadBadgeCount: {
      treatmentPlanUnread: 0,
      conversationsUnread: 0,
      labsUnread: 0,
      documentsUnread: 0,
      paymentsUnread: 0,
      medicationUnread: 0,
      appointmentsUnread: 0
    },
    dismissible: false
  }),
  beforeMount() {
    //this.getRulesByUrl();
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...messages.mapComputed(['currentNotification', 'updateUnreadCount']),
    ...partner.mapComputed(['getPartner']),
    styleCustom() {
      return window.innerWidth < 1264 || device === 'mobile'
        ? {
            'margin-top': '49px'
          }
        : {
            'margin-top': '0'
          };
    },
    checkCryoTitle() {
      const urls = this.permitionsMenu;
      if (urls == null && this.checkUrl != 'connect') return true;
      return urls?.ft_cryo_title ? true : false;
    },
    checkCryoFooter() {
      const urls = this.permitionsMenu;
      if (urls == null && this.checkUrl != 'connect') return true;
      return urls?.ft_footer ? true : false;
    },
    routes() {
      const routes = authorizedRoutes.filter(
        (el) => !el?.meta?.hide && el?.meta?.svg
      );

      // Logout if user is not logged in
      if (!this.user) {
        this.logoutApp();
      }

      let _router = routes;

      if (!this.user?.features?.cryo && !this.user?.features?.cryo_test) {
        _router = _router.filter((el) => el.name !== 'Cryo Dashboard');
        _router = _router.filter((el) => el.name !== 'Activities');
        _router = _router.filter((el) => el.name !== 'Invoices');
        _router = _router.filter((el) => el.name !== 'Payment History');
      }

      if (!this.user?.clinicSettings?.show_financial_menu) {
        _router = _router.filter((el) => el.name !== 'Finance');
      }

      if (!this.user?.clinicSettings?.show_partner_menu) {
        _router = _router.filter((el) => el.name !== 'My Partner');
      }

      if (this.partnerView === 'true') {
        _router = _router.filter((el) => el.name !== 'Patient Forms');
        _router = _router.filter((el) => el.name !== 'My Account');
      }

      _router.forEach((item) => {
        if (item.name === 'Documents') {
          item.badge = this.unreadBadgeCount.documentsUnread;
        } else if (item.name === 'Messages') {
          item.badge = this.unreadBadgeCount.conversationsUnread;
        } else if (item.name === 'Labs') {
          item.badge = this.unreadBadgeCount.labsUnread;
        } else if (item.name === 'Treatment Plan') {
          item.badge =
            this.unreadBadgeCount.medicationUnread +
            this.unreadBadgeCount.appointmentsUnread;
        }
      });

      //Remove menu by address
      // if (this.urls) {

      // if (!this.urls.ft_call_us) {
      //   _router = _router.filter((el) => el.name !== 'Call Us');
      // }

      // if (!this.urls.ft_checklist) {
      //   _router = _router.filter((el) => el.name !== 'Checklist');
      // }

      // // Start menu Cryo
      // if (!this.urls.ft_cryo && !this.user?.features?.cryo_test) {
      //   _router = _router.filter((el) => el.name !== 'Cryo Dashboard');
      // }
      // if (!this.urls.ft_activities && !this.user?.features?.cryo_test) {
      //   _router = _router.filter((el) => el.name !== 'Activities');
      // }
      // if (!this.urls.ft_invoices && !this.user?.features?.cryo_test) {
      //   _router = _router.filter((el) => el.name !== 'Invoices');
      // }
      // if (!this.urls.ft_payment_history && !this.user?.features?.cryo_test) {
      //   _router = _router.filter((el) => el.name !== 'Payment History');
      // }
      // // End menu Cryo

      // if (!this.urls.ft_documents) {
      //   _router = _router.filter((el) => el.name !== 'Documents');
      // }

      // if (!this.urls.ft_finance) {
      //   _router = _router.filter((el) => el.name !== 'Finance');
      // }

      // if (!this.urls.ft_labs) {
      //   _router = _router.filter((el) => el.name !== 'Labs');
      // }

      // if (!this.urls.ft_messages) {
      //   _router = _router.filter((el) => el.name !== 'Messages');
      // }

      // if (!this.urls.ft_my_partner) {
      //   _router = _router.filter((el) => el.name !== 'My Partner');
      // }

      // if (!this.urls.ft_directions) {
      //   _router = _router.filter((el) => el.name !== 'Directions');
      // }

      // if (!this.urls.ft_patient_forms) {
      //   _router = _router.filter((el) => el.name !== 'Patient Forms');
      // }

      // if (!this.urls.ft_settings) {
      //   _router = _router.filter((el) => el.name !== 'Settings');
      // }

      // if (!this.urls.ft_support) {
      //   _router = _router.filter((el) => el.name !== 'Call Us');
      // }
      // }
      return _router;
    },
    clinicName() {
      return this.user?.clinic;
    },
    userName() {
      const fullName = this.user?.fullName;

      const [match] = fullName.match(/(\S+) /) || [];

      return match?.trim();
    },
    salutation() {
      const hours = moment().hours();
      if (hours >= 5 && hours <= 11) return 'Good morning';
      if (hours >= 12 && hours <= 16) return 'Good afternoon';
      if (hours >= 17 || hours <= 4) return 'Good evening';
      return 'Hello';
    }
  },
  methods: {
    ...auth.mapMethods(['logout', 'switchPatients']),
    ...messages.mapMethods(['getConversationsAsync']),
    ...partner.mapMethods(['getLocalPartner', 'setLocalPartner']),
    getPermissions(url) {
      this.loadingRoutes = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/settings/features`,
          {
            headers: {
              origin: url
            }
          }
        )
        .then((response) => {
          if (response.data) {
            sessionStorage.setItem(
              'permitionsMenu',
              JSON.stringify(response.data)
            );
            this.permitionsMenu = response.data;
            this.getRulesByUrl();
          }
          this.loadingRoutes = false;
        })
        .catch((error) => {
          this.loadingRoutes = false;
          console.error(error);
        });
    },
    goToRoute(path) {
      this.isLogout = false;
      if (this.lastRoute && this.lastRoute === '/forms') {
        this.path = path;
        this.open = true;
      } else {
        this.lastRoute = path;
        if (this.$router.currentRoute.path !== path) {
          this.$router.push({ path: path });
        }
      }
    },
    toGoBack() {
      const paths = this.$route.path.split('/').filter((item) => item);
      if (paths.length > 1) {
        this.$router.go(-1);
      } else if (paths.length === 1) {
        this.$router.push('/');
      }
    },
    actionGo() {
      if (this.isLogout) {
        this.isLogout = false;
        logoutHelper(this.logout);
      } else {
        this.open = false;
        this.lastRoute = this.path;
        if (this.$router.currentRoute.path !== this.path) {
          this.$router.push({ path: this.path });
        }
      }
    },
    actionCancel() {
      this.open = false;
    },
    getRulesByUrl() {
      this.urls = this.permitionsMenu; //JSON.parse(sessionStorage.getItem('permitionsMenu'));
      // this.defaultRoute();
    },
    defaultRoute() {
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push({ path: `/` });
      }
    },
    logoutApp() {
      this.setLocalPartner(null);
      this.isLogout = false;
      logoutHelper(this.logout);
    },
    logoutReload() {
      logoutHelper(this.logout);
    },
    async checkVersions() {
      const currentVersion = await api.Versions.latestChanges();
      if (currentVersion === undefined) return;
      try {
        const storedVersionId = await Storage.get('versionId');
        if (storedVersionId != currentVersion?.id) {
          this.changes = currentVersion.notes;
          this.dialog = true;
          Storage.set('versionId', currentVersion?.id);
        }
      } catch (error) {
        Storage.set('versionId', currentVersion?.id);
        console.error(error);
      }
    },
    async getUnreadBadgeCounts() {
      await api.Auth.getUnreadBadgeCount()
        .then((result) => {
          this.unreadBadgeCount = { ...this.unreadBadgeCount, ...result };
          this.menuKey = Math.random();
        })
        .catch((error) => {});
    },
    async setPartner() {
      this.loading = true;

      await this.getLocalPartner()
        .then((partner) => this.setLocalPartner(partner))
        .finally(() => {
          this.loading = false;
        });
      this.checkNotificationPartner();
    },
    async checkNotificationPartner() {
      const invitePending =
        this.getPartner &&
        this.getPartner.consent === 0 &&
        this.getPartner.isPartner === 1 &&
        !this.getPartner.noShow;

      if (invitePending) {
        await this.$topbar.show({
          color: 'deep-orange darken-2 white--text',
          text: 'You have a partner invitation',
          onAction: async () => {
            await this.$router.push('/my-partner');
          },
          dismissible: true
        });
      }
      const viewPartner = this.getPartner && this.getPartner.viewPartner;

      if (viewPartner) {
        await this.$topbar.show({
          color: 'purple darken-2 white--text',
          text: 'You are viewing as your partner',
          onAction: async () => {
            const authOriginalLogin = JSON.parse(
              localStorage.getItem('authOriginalLogin')
            );
            if (authOriginalLogin?.patientId) {
              return await this.switchPatients(
                authOriginalLogin.patientId
              ).then(() => {
                this.setLocalPartner(null);
                this.viewPartner = false;
                location.reload();
              });
            }
            this.$store.commit('showSnackbar', {
              message:
                'Please re-login because your previous session has expired. You will be redirected to the login page.',
              color: 'error'
            });
            setTimeout(() => {
              this.logoutReload();
            }, 2000);
          }
        });
      }

      if (!invitePending && !viewPartner) {
        await this.$topbar.hide();
      }
    }
  },
  watch: {
    $route() {
      this.$store.commit('setUpdateRouteName', null);
      this.setPartner();
    },
    '$store.state.accessDenied'(value) {
      if (value) {
        this.$router.push({ name: 'Login' });
      }
    },
    '$store.state.unreadStatus'() {
      this.getUnreadBadgeCounts();
    },
    updateUnreadCount(status) {
      if (status) {
        this.unreadBadgeCount = {
          treatmentPlanUnread: 0,
          conversationsUnread: 0,
          labsUnread: 0,
          documentsUnread: 0,
          paymentsUnread: 0,
          medicationUnread: 0,
          appointmentsUnread: 0
        };
        this.getUnreadBadgeCounts();
        this.$store.dispatch('messages/pullNotificationUnreadCount', null);
      }
    },
    currentNotification(currentNotification) {
      if (currentNotification != null) {
        try {
          if (currentNotification.toLowerCase().includes('new message')) {
            this.unreadBadgeCount.conversationsUnread += 1;

            this.$store.commit('showSnackbar', {
              message: 'You have a new message',
              color: 'green'
            });
          } else if (
            currentNotification.toLowerCase().includes('new lab results')
          ) {
            this.$store.commit('showSnackbar', {
              message: 'There are new lab results for you',
              color: 'green'
            });
            this.unreadBadgeCount.labsUnread += 1;
          } else if (currentNotification.toLowerCase().includes('documents')) {
            this.$store.commit('showSnackbar', {
              message: 'You have an updated document',
              color: 'green'
            });

            this.unreadBadgeCount.documentsUnread += 1;
          } else if (
            currentNotification.toLowerCase().includes('medications')
          ) {
            this.$store.commit('showSnackbar', {
              message: 'Your medications have been updated',
              color: 'green'
            });
            this.unreadBadgeCount.medicationUnread += 1;
          } else if (
            currentNotification.toLowerCase().includes('appointments')
          ) {
            this.$store.commit('showSnackbar', {
              message: 'Your appointments have been updated',
              color: 'green'
            });
            this.unreadBadgeCount.appointmentsUnread += 1;
          }

          this.$store.dispatch('messages/pullNotification', null);
          this.menuKey = Math.random();
        } catch (error) {
          console.log('notifications:', error);
        }
      }
    }
  },
  mounted() {
    this.getUnreadBadgeCounts();
    this.loadingRoutes = true;
    let url = window.location.href;
    let result = url.replace(/(^\w+:|^)\/\//, '');
    let index = result.indexOf(':');
    url = result.substring(0, index);

    this.checkUrl = url;
    this.getPermissions(url);

    this.checkVersions();
    this.setPartner();
  }
};
</script>

<style scoped>
.wrapper-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}
.content-app {
  display: flex;
  flex: 1;
  position: relative;
  min-height: 100%;
}
.v-main {
  position: relative;
  min-height: 100%;
}
</style>
<style lang="scss">
.v-application--wrap {
  min-height: 100%;
  height: 100%;
}
.theme--light.v-application {
  background: rgb(218, 218, 218);
}
.subtitle {
  color: #fff;
  font-size: 1rem;
}
.theme--light.v-application
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td,
.theme--light.v-application
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > td,
.theme--light.v-application
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tfoot
  > tr
  > td {
  height: 80px;
}
.theme--light.v-application
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
  background: #f5f5f5;
}

.navigation-drawer {
  height: 100% !important;
  background: linear-gradient(180deg, #224d81 0%, #36bebf 100%);
}
.top-menu {
  .v-sheet.v-card {
    border-radius: 0;
  }
}
.main-widgets {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.row-menu {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 358px;
  height: 171px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.salutation {
  font-family: 'SF Pro Display';
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.0041em;
  color: #192c53;
}
.clinic-name {
  font-weight: 600;
  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  color: #192c53;
}

.container {
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
}

.badge {
  position: absolute;
  top: 35px;
  right: 35px;
  width: 30px;
  height: 30px;
  background-color: #d6366e;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: white;
  font-weight: bold;
}
</style>
