<template>
  <loading-circle v-if="!loaded" />
  <v-card flat height="100%" v-else>
    <Messages
      @submit="messageSubmit"
      :messages="botMessages"
      :cols="'auto'"
      @onBack="$emit('onBack')"
      :subject="botUserName"
    >
      <MessageTextInput />
    </Messages>
  </v-card>
</template>
<script>
import Messages from './Messages.vue';
import { api, auth } from '../../sharedPlugin';
import LoadingCircle from '../LoadingCircle.vue';
import MessageTextInput from './MessageTextInput.vue';

export default {
  data: () => ({
    botMessages: [],
    botResponse: {},
    loaded: false,
    convoWatermark: null,
    botConversationId: null,
    botUserName: 'Brooke Bot',
    isDisabled: false
  }),
  components: { Messages, LoadingCircle, MessageTextInput },
  computed: {
    ...auth.mapComputed(['user'])
  },
  async mounted() {
    api.Brookebot.startConversation()
      .then((res) => {
        const botObj = [...res.messages];
        this.convoWatermark = res.watermark;
        this.botConversationId = res.conversationId;
        for (var x in botObj) {
          var tempObj = this.newMessageObject(
            botObj[x].from.id,
            this.botUserName,
            botObj[x].text,
            new Date(botObj[x].timestamp)
          );
          this.botMessages.push(tempObj);
        }
      })
      .catch((e) => console.log('err:', e))
      .finally(() => {
        this.loaded = !this.loaded;
      });
  },
  methods: {
    messageSubmit(message) {
      const botObjToSend = {
        message,
        conversationId: this.botConversationId,
        waterMark: this.convoWatermark
      };
      this.botMessages.push(
        this.newMessageObject(this.user.userId, 'You', message, Date.now())
      );
      api.Brookebot.sendMessage(botObjToSend)
        .then((res) => {
          const botMessage = res.messages.pop();
          this.convoWatermark = res.watermark;

          if (botMessage.text.includes('Lets get you to your care team')) {
            return this.leavePage();
          }

          this.botMessages.push(
            this.newMessageObject(
              botMessage.id,
              this.botUserName,
              botMessage.text,
              botMessage.timestamp
            )
          );
        })
        .catch((e) => console.log('err:', e));
    },
    newMessageObject(id, userName, text, date) {
      var tempObj = { userId: null, username: '', message: '', sentDate: Date };
      //this checks if the userId can be made into an int
      //by changing it to int, it can be read in message.vue as an int
      //it will map to userId and determine the color of the message
      var newId = parseInt(id);
      if (isNaN(newId)) {
        tempObj.userId = id;
      } else {
        tempObj.userId = newId;
      }
      tempObj.username = userName;
      tempObj.message = text;
      tempObj.sentDate = new Date(date);
      return tempObj;
    },
    //this is gonna pop up the button to see if they want new message form or not
    //if they do, emit exit bot convo and go to new message form
    //if they don't get rid of question
    endConversation() {
      this.isDisabled = true;
    },
    leavePage() {
      //put end bot convo here to make sure its closed
      api.Brookebot.endConversation(this.botConversationId);
      //after that, emit the exit to the form
      this.$emit('finished');
    }
  }
};
</script>
<style scoped>
.vcard {
  background-color: lightgrey;
}
</style>
