<template>
  <v-menu
    top
    left
    offset-y
    offset-x
    transition="slide-x-reverse-transition"
    v-model="isShown"
    :close-on-click="false"
  >
    <initialization-check>
      <v-sheet v-if="creatingMessage" class="msg" @click.stop>
        <BrookeBot
          v-if="showBrookebot"
          @onBack="clearMessage"
          @finished="brookebotFinish"
        />
        <NewMessageForm
          v-else
          @onBack="clearMessage"
          @finished="creatingMessage = false"
        />
      </v-sheet>
      <v-sheet v-else class="msg" @click.stop>
        <FireBaseMessages v-if="selectedConversation" />
        <Conversations
          v-else
          styles="max-height: 88%; "
          :isCloseShow="isCloseShow"
          @newMessage="createMessage"
          @close="isShown = false"
        />
      </v-sheet>
    </initialization-check>
  </v-menu>
</template>

<script>
import { messages, auth } from '../../sharedPlugin';
import FireBaseMessages from './FirebaseMessages.vue';
import NewMessageForm from './NewMessageForm.vue';
import Conversations from './Conversations.vue';
import InitializationCheck from './InitializationCheck.vue';
import BrookeBot from './BrookeBotMessages.vue';

export default {
  components: {
    FireBaseMessages,
    NewMessageForm,
    Conversations,
    InitializationCheck,
    BrookeBot
  },
  data: () => ({
    creatingMessage: false,
    showBrookebot: false,
    isCloseShow: true
  }),
  computed: {
    ...messages.mapComputed([
      'shown',
      'totalUnreadCount',
      'selectedConversation'
    ]),
    ...auth.mapComputed(['user']),
    isShown: {
      get() {
        return this.shown;
      },
      set(val) {
        this.setShown(val);
      }
    }
  },
  methods: {
    ...messages.mapMethods(['setShown']),
    clearMessage() {
      this.showBrookebot = false;
      this.creatingMessage = false;
    },
    createMessage() {
      if (this.user.features.brookebot) {
        this.showBrookebot = true;
      }
      this.creatingMessage = true;
    },
    brookebotFinish() {
      this.showBrookebot = false;
    }
  }
};
</script>

<style scoped>
.v-menu__content {
  overflow: hidden;
}
</style>

<style>
.body {
  height: 100%;
}

.msg {
  width: 500px;
  height: 500px;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: 16px;
}
</style>
