<template>
  <v-form ref="form" @submit.prevent="sendMessage" class="flex-grow">
    <v-text-field
      id="messageInput"
      label="Send message..."
      v-model="message"
      :rules="inputRules"
      outlined
      hide-details
    >
      <v-btn slot="append" icon @click="sendMessage">
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-text-field>
  </v-form>
</template>
<script>
export default {
  data: () => ({
    message: '',
    inputRules: [(v) => !!v || 'Input is required!'],
    file: undefined
  }),
  methods: {
    sendMessage() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.message);
        this.message = '';
      }
    }
  }
};
</script>
<style scoped>
.v-form ::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 10px;
}
</style>
